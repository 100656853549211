<template>
    <div>
        <Menu></Menu>
        <div class="w1200 m-auto">
            <!-- 笔试 -->
            <div class="box">
                <div class="boxs">
                    <div class="ExamListText mt20">
                        笔试
                    </div>
                    <p class="fs20 color-2 textAliginC mt20">剩余时间：{{ restTime ? restTime : '00:00:00' }}</p>
                    <div class="examinationList">
                        <el-form ref="form" :model="form" label-width="80px" label-position="top">
                            <el-form-item :label="form.subject_title + `【 ${option ? option : ''} 】`">
                                <el-radio-group v-model="option">
                                    <el-radio :label="item.key" v-for="(item, index) in form.option" :key="index">
                                        {{ item.key }} : {{ item.content }}
                                    </el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-form>
                    </div>
                    <!-- <div class="Submit">
                    提交答案
                </div> -->
                    <div class="Submit2">

                        <div class="Submit" @click="next(true)" v-if="this.num >= this.TitleNo.length - 1">
                            提交答案
                        </div>
                        <div class="Submit" @click="next(false)" v-else>
                            下一题
                        </div>
                    </div>
                </div>
                <div class="AnswerSheet">
                    <div class="topTitle">
                        答题卡
                    </div>
                    <div class="TitleNoList">
                        <div v-for="(item, index) in TitleNo" :key="index" @click="tab(item.id, index)"
                            :class="num == index ? 'active' : ''"
                            :style="{ background: item.is_show !== 'false' ? '#CD0000' : '', border: item.is_show !== 'false' ? '1px solid #CD0000' : '', color: item.is_show !== 'false' ? 'white' : '' }">
                            {{ index + 1 }}
                        </div>
                        <div class="CoveringLayer"></div>
                        <div class="CoveringLayer"></div>
                        <div class="CoveringLayer"></div>
                        <div class="CoveringLayer"></div>
                        <div class="CoveringLayer"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="is_show2" class="dialogBox">
            <!--  提交答案 -->
            <el-dialog title="提示" :visible.sync="dialogVisible">
                <p class="title">一旦交卷则不能再作答或修改答案。</p>
                <div class="btnBox mt45">
                    <div class="cancel " @click="is_show2 = false">继续作答</div>
                    <div class="ml15 Submit3" @click="SubmitAnswers">确认提交</div>
                </div>
            </el-dialog>
        </div>
        <div v-if="overtime" class="dialogBox">
            <!--  超时 -->
            <el-dialog title="提示" :visible.sync="dialogVisible">
                <p class="title">您的笔试作答超时，已为您自己动提交</p>
                <div class="btnBox">
                    <div class="Submit" @click="overtime = false">关闭</div>
                </div>
            </el-dialog>
        </div>
        <div v-if="is_show3" class="dialogBox">
            <!--  提交答案 -->
            <el-dialog title="提示" :visible.sync="dialogVisible">
                <p class="title">您还有试题未作答，是否确认提交？</p>
                <div class="btnBox mt45">
                    <div class="cancel " @click="is_show3 = false">继续作答</div>
                    <div class="ml15 Submit3" @click="SubmitAnswers">确认提交</div>
                </div>
            </el-dialog>
        </div>
        <div v-if="is_show4" class="dialogBox">
            <!--  提交答案 -->
            <el-dialog title="提示" :visible.sync="dialogVisible">
                <p class="title">您还有试题未作答，退出将自动交卷</p>
                <div class="btnBox mt45">
                    <div class="cancel " @click="is_show4 = false">继续作答</div>
                    <div class="ml15 Submit3" @click="SubmitAnswers">确认退出</div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
//javascript加码解码
// var objUrl = "http://www.iteye.com";
// alert(encodeURI(objUrl));
// alert(encodeURIComponent(objUrl));
// var objUrl2 = "http%3A%2F%2Fwww.iteye.com";
//  alert(decodeURI(objUrl2));
//  alert(decodeURIComponent(objUrl2));
import Menu from '@/components/menu.vue'
// 调用moment插件计算时间差
import { getExaminationDetails, getSubjectInfo, objectiveSubmit } from "@/api/examination"
export default {
    components: { Menu },
    data() {
        window.onbeforeunload = function (e) {
            console.log('刷新页面了')
            return ' ';
        }
        document.oncontextmenu = function (event) {
            if (window.event) {
                event = window.event
            }
            return false;
        }
        return {
            num: 0,
            is_show2: false,
            is_show3: false,
            is_show4: false,
            is_show5: false,
            dialogVisible: true,
            form: {},
            option: '',
            TitleNo: [],
            restTime: "", // 剩余时间
            startDataTime: "2022-06-09 00:00:00", // 开始时间，自己设置或数据库获取
            endDataTime: '', // 结束时间，自己设置或数据库获取
            timeStamp: null, // 接收剩余时间戳
            timer: null,
            overtime: false,
            A_D: ['A', 'B', 'C', 'D']

        }
    },
    methods: {
        getTitleNoListFn() {
            getExaminationDetails({ objective_id: this.$route.query.id }).then(res => {
                if (res.code == 200) {
                    this.TitleNo = res.data
                    localStorage.setItem('TitleNo', encodeURI(JSON.stringify(res.data)))
                    this.tab(this.TitleNo[0].id, 0)
                }
            })
        },
        // 时间戳转时分秒
        toHHmmss(data) {
            var s;
            var hours = parseInt((data % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = parseInt((data % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = (data % (1000 * 60)) / 1000;
            s = (hours < 10 ? ('0' + hours) : hours) + ':' + (minutes < 10 ? ('0' + minutes) : minutes) + ':' + (seconds < 10 ? ('0' + seconds) : seconds);
            if (s.length > 8) {
                s = s.slice(0, 8)
            }
            return s;
        },
        // 初始化获取时间
        getRestTime() {
            // 赋值转时分秒
            this.restTime = this.toHHmmss(this.timeStamp);
            localStorage.setItem('endDataTime', encodeURI(`2022-06-09 ${this.restTime}`))
            // 所以不是大于0秒
            if (this.timeStamp >= 1000) {
                // 1000为一秒
                // 如果大于1秒
                this.timeStamp = this.timeStamp - 1000;
            } else {
                // 倒计时最后一秒将剩余时间修改
                this.restTime = "00:00:00";
                // 清除定时器
                this.overtime = true
                this.SubmitAnswers()
                clearInterval(this.timer)
                // 在这里编写考试交卷或自动取消订单的功能
            }
        },
        //选项卡点击
        tab(id, index) {
            getSubjectInfo({ subject_id: id }).then(res => {
                if (res.code == 200) {
                    this.form = res.data
                    this.form.option = JSON.parse(this.form.option)
                }
            })
            this.num = index
            localStorage.setItem('num', this.num)
            let List = JSON.parse(decodeURI(localStorage.getItem('TitleNo')))
            List.forEach(item => {
                if (item.id == id) {
                    this.option = item.option ? item.option : ''
                }
            });
        },
        //下一题
        next(is_show) {
            if (this.num >= this.TitleNo.length - 1) {
                this.TitleNo.forEach(item => {
                    if (item.is_show == false) {
                        this.is_show2 = false
                        this.is_show3 = true
                    }
                })
                this.num = this.TitleNo.length - 1
            } else {
                this.num++
                getSubjectInfo({ subject_id: this.TitleNo[this.num].id }).then(res => {
                    if (res.code == 200) {
                        this.form = res.data
                        this.form.option = JSON.parse(this.form.option)
                    }
                })
                this.option = this.TitleNo[this.num].option ? this.TitleNo[this.num].option : ''
            }
            localStorage.setItem('num', this.num)
            this.is_show2 = is_show
        },
        //提交答案
        SubmitAnswers() {
            let form = {}
            var objective_score = 0
            form.objective_id = this.$route.query.id
            this.TitleNo.forEach(item => {
                if (item.is_correct) {
                    objective_score++
                }
            })
            form.objective_score = objective_score == 0 ? 0.01 : objective_score
            form.objective_data = JSON.stringify(this.TitleNo)
            objectiveSubmit(form).then(res => {
                if (res.code == 200) {
                    localStorage.removeItem('num')
                    localStorage.removeItem('endDataTime')
                    localStorage.removeItem('TitleNo')
                    this.$message.success('提交成功');
                    this.$router.go(-1)
                    this.is_show5 = true
                } else if (res.code == 403) {
                    localStorage.removeItem('num')
                    localStorage.removeItem('endDataTime')
                    localStorage.removeItem('TitleNo')
                    this.$router.go(-1)
                    this.is_show5 = true
                }
            })


        }

    },
    // 获取时间差长度只需要做一次
    created() {
        if (localStorage.getItem('endDataTime')) {
            this.endDataTime = decodeURI(localStorage.getItem('endDataTime')) + ''
        } else {
            this.endDataTime = '2022-06-09 01:00:00'
        }
        const time = this.$moment(this.endTime).diff(this.$moment(new Date()));
        // 处理字符串为时间类型
        let startTime = Date.parse(this.startDataTime.replace(/-/g, "-"));
        let endTime = Date.parse(this.endDataTime.replace(/-/g, "-"));
        // 调用moment插件计算时间差(做完这一步timeStamp为时间戳了)
        this.timeStamp = this.$moment(endTime).diff(this.$moment(startTime));
    },
    // 计算
    mounted() {

        if (localStorage.getItem('TitleNo')) {
            let obj = localStorage.getItem('TitleNo')
            this.TitleNo = JSON.parse(decodeURI(obj))
            this.tab(this.TitleNo[0].id, 0)
        } else {
            this.getTitleNoListFn()
        }
        if (localStorage.getItem('num')) {
            this.num = localStorage.getItem('num')
            this.option = this.TitleNo[this.num] ? this.TitleNo[this.num].option : ''
        }

        // 倒计时,每一秒执行一次
        if (this.timer) clearInterval(this.timer)
        this.timer = setInterval(this.getRestTime, 1000)

    },
    // 退出页面清除定时器
    beforeDestroy() {
        window.onbeforeunload = function (e) {
        }
        document.oncontextmenu = function (event) {
        }
        clearInterval(this.timer);
    },
    watch: {
        dialogVisible(val) {
            if (val == false) {
                this.is_show2 = false
                this.is_show3 = false
                this.dialogVisible = true
            }
        },
        option() {
            if (this.option) {
                this.TitleNo[this.num].is_show = true
                this.$set(this.TitleNo[this.num], 'is_correct', this.option == this.A_D[Number(this.form.answer) - 1])
                this.$set(this.TitleNo[this.num], 'option', this.option)
                this.option = this.TitleNo[this.num] ? this.TitleNo[this.num].option : this.TitleNo[this.num - 1].option
            } else {
                this.$set(this.TitleNo[this.num], 'is_correct', false)
                this.$set(this.TitleNo[this.num], 'option', '')
            }
            localStorage.setItem('TitleNo', encodeURI(JSON.stringify(this.TitleNo)))

        }
    },
    beforeUnmount() {
        this.SubmitAnswers()
    },
    beforeRouteLeave(to, from, next) {
        this.is_show4 = true
        if (this.is_show5) {
            next()
        }
    }

}
</script>
  
<style  scoped>
.boxs {
    margin-top: 30px;
    width: 935px;
    height: 740px;
    padding: 20px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.cancel {
    width: 200px;
    height: 48px;
    background: #999999;
    border-radius: 8px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
    line-height: 48px;
}

.dialogBox>>>.el-dialog__title {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
}

.dialogBox>>>.el-dialog {
    width: 720px;
    background: #FFFFFF !important;
    border-radius: 10px !important;
}


.dialogBox>>>.el-dialog__header {
    width: 720px;
    box-sizing: border-box;
    height: 66px;
    border-radius: 10px 10px 0 0;
    background: #F5F5F5;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.2);
}

.btnBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.Submit3 {
    width: 200px;
    height: 48px;
    background: #CC0000;
    border-radius: 8px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
    line-height: 48px;
}

.ExamListText {
    justify-content: center;
    color: #CD0000;
    display: flex;
    align-items: center;
    font-size: 22px;
}

.ExamListText::before {
    content: '';
    width: 80px;
    display: inline-block;
    margin-right: 20px;
    height: 2px;
    background: #CD0000;
}

.ExamListText::after {
    content: '';
    width: 80px;
    display: inline-block;
    height: 2px;
    margin-left: 20px;
    background: #CD0000;
}



.title {
    text-align: center;
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    margin-bottom: 10px;
}



.examinationList>>>.el-radio__input.is-checked+.el-radio__label {
    color: #333333 !important;
}

.examinationList {
    display: flex;
    width: 90%;
    justify-content: space-between;
    margin: 20px auto;
}

.examinationList>>>.el-radio__label {

    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    position: relative;
    top: 3px;
    color: #333333;

}

.examinationList>>>.el-form-item__label {

    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;

}

.examinationList>>>.el-radio {
    width: 400px;
    margin-top: 30px;
}

.Submit {
    width: 440px;
    cursor: pointer;
    height: 48px;
    background: #CC0000;
    border-radius: 8px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    margin: 100px auto;
    line-height: 48px;
    margin-bottom: 0px;
}

.Submit2 {
    display: flex;
    width: 60%;
    justify-content: space-between;
    margin: 100px auto;
    margin-bottom: 0px;
}


.AnswerSheet {
    margin-top: 30px;
    padding: 20px;
    box-sizing: border-box;
    width: 245px;
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.box {
    display: flex;
    justify-content: space-between;
}

.topTitle {
    height: 21px;
    font-size: 22px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    text-indent: 5px;
    color: #333333;
    line-height: 21px;
    border-left: 2px solid #CD0000;
}

.TitleNoList {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.TitleNoList div {
    width: 30px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #999999;
    border-radius: 50%;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #666666;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

.active {
    border: 1px solid #CD0000 !important;
    color: #CC0000 !important;
    background: white !important;
}

.CoveringLayer {
    visibility: hidden !important;
    height: 0 !important;
    margin: 0 !important;
}

#active2 {
    background: #CD0000 !important;
    color: white !important;
    border: 1px solid #CD0000 !important;
}
</style>